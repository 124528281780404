import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { getFirestore, doc, setDoc, collection, addDoc, updateDoc, arrayUnion, getDocs } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import MenuPane from "./MenuPane";
import VitalQueTextField from "../landing-page/VitalQueTextField";
import Typography from "@mui/material/Typography";

// Firebase configuration
const db = getFirestore();
const auth = getAuth();

const AdminHome = () => {
  const [open, setOpen] = useState(false);
  const [clinicOpen, setClinicOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("123456"); // default password
  const [role, setRole] = useState("provider");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [clinicName, setClinicName] = useState("");
  const [selectedPartnerId, setSelectedPartnerId] = useState("");
  const [partner, setPartner] = useState("");
  const [provider, setProvider] = useState("");
  const [partners, setPartners] = useState([]);
  const [providers, setProviders] = useState([]);

  // Fetch partners and providers from Firestore
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        // Fetching users with role "partner" from the Firestore users collection
        const partnersSnapshot = await getDocs(collection(db, "users"));
        const partnersData = partnersSnapshot.docs
          .filter((doc) => doc.data().role === "partner")
          .map((doc) => ({ id: doc.id, ...doc.data() }));
  
        // Fetching all providers
        const providersSnapshot = await getDocs(collection(db, "providers"));
        const providersData = providersSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
  
        // Set state for partners and providers
        setPartners(partnersData);
        setProviders(providersData);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
  
    fetchUsers();
  }, []);

  // Handle dialog open and close
  const handleClickOpen = () => setOpen(true);
  const handleClinicOpen = () => setClinicOpen(true);
  const handleClose = () => setOpen(false);
  const handleClinicClose = () => setClinicOpen(false);

  // Handle account creation
  const handleCreateAccount = async () => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      await setDoc(doc(db, "users", user.uid), {
        role: role,
        email: email,
      });

      if (role === "provider") {
        await setDoc(doc(db, "providers", user.uid), {
          assigned_clinics: [],
          first_name: firstName,
          last_name: lastName,
        });
      }

      handleClose();
    } catch (error) {
      console.error("Error creating user: ", error);
    }
  };

  // Handle clinic creation
  const handleCreateClinic = async () => {
    try {
      // Step 1: Create the clinic in the clinics collection
      const clinicDocRef = await addDoc(collection(db, "clinics"), {
        clinic_name: clinicName,
        partner_email: partner,
        partner_id: selectedPartnerId, // Store the partner ID
      });

      // Get the newly created clinic's ID
      const clinicId = clinicDocRef.id;

      // Step 1.1: Create an empty patients subcollection (add an empty doc)
      await setDoc(doc(db, "clinics", clinicId, "patients", "empty_patient"), {});

      // Step 2: Update the selected provider's assigned_clinics field in the providers collection
      if (provider) {
        const providerDocRef = doc(db, "providers", provider);
        await updateDoc(providerDocRef, {
          assigned_clinics: arrayUnion({
            clinic_id: clinicId,
            assigned_patients: [], // Empty array for assigned patients
          }),
        });
      }

      // Close the dialog after successful creation
      handleClinicClose();
    } catch (error) {
      console.error("Error creating clinic: ", error);
    }
  };

  return (
    <Grid container spacing={0} style={{ minHeight: "100vh" }}>
      <MenuPane />
      <Grid container spacing={0} style={{ marginTop: "75px" }}>
        {/* Add Role Button */}
        <Typography variant="body1" className="basefont-regular" style={{padding: "20px"}}>
        When onboarding a clinic, create roles first then create the clinic.
       </Typography>
        <Button
          variant="outlined"
          className="active-font"
          style={{
            margin: "10px",
            borderColor: "#4BD891",
            color: "black",
            fontSize: "1.0rem",
            minWidth: "140px",
            height: "40px",
          }}
          onClick={handleClickOpen}
        >
          Add Role
        </Button>

        {/* Add Clinic Button */}
        <Button
          variant="outlined"
          className="active-font"
          style={{
            margin: "10px",
            borderColor: "#4BD891",
            color: "black",
            fontSize: "1.0rem",
            minWidth: "140px",
            height: "40px",
          }}
          onClick={handleClinicOpen}
        >
          Add Clinic
        </Button>
      </Grid>

      {/* Dialog for Add Role */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className="basefont-regular">Add Role</DialogTitle>
        <DialogContent>
          <VitalQueTextField
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <VitalQueTextField
            margin="dense"
            label="First Name"
            fullWidth
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <VitalQueTextField
            margin="dense"
            label="Last Name"
            fullWidth
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />

          {/* Select for Role */}
          <FormControl fullWidth style={{ marginTop: "20px" }}>
            <InputLabel id="provider-select-label" style={{ color: "#9B9B9B" }}>
              Role
            </InputLabel>
            <Select
              labelId="provider-select-label"
              value={role}
              label="Role"
              className="basefont-regular"
              onChange={(e) => setRole(e.target.value)}
              style={{ color: "#6A6A6A" }}
            >
              <MenuItem value="admin" className="basefont-regular" style={{ color: "#6A6A6A" }}>
                admin
              </MenuItem>
              <MenuItem value="provider" className="basefont-regular" style={{ color: "#6A6A6A" }}>
                provider
              </MenuItem>
              <MenuItem value="partner" className="basefont-regular" style={{ color: "#6A6A6A" }}>
                partner
              </MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            className="active-font"
            style={{
              marginTop: "50px",
              marginBottom: "20px",
              borderColor: "#4BD891",
              color: "black",
              fontSize: "1.0rem",
              minWidth: "140px",
              height: "40px",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleCreateAccount}
            variant="outlined"
            className="active-font"
            style={{
              marginTop: "50px",
              marginBottom: "20px",
              borderColor: "#4BD891",
              color: "black",
              fontSize: "1.0rem",
              minWidth: "140px",
              height: "40px",
            }}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for Add Clinic */}
      <Dialog open={clinicOpen} onClose={handleClinicClose}>
        <DialogTitle className="basefont-regular">Add Clinic</DialogTitle>
        <DialogContent>
          <VitalQueTextField
            margin="dense"
            label="Clinic Name"
            fullWidth
            value={clinicName}
            onChange={(e) => setClinicName(e.target.value)}
          />

          {/* Select for Partner */}
          <FormControl fullWidth style={{ marginTop: "20px" }}>
            <InputLabel id="partner-select-label" className="basefont-regular" style={{ color: "#9B9B9B" }}>
              Partner
            </InputLabel>
            <Select
              labelId="partner-select-label"
              value={partner}
              label="Partner"
              className="basefont-regular"
              onChange={(e) => {
                const selectedPartner = partners.find((p) => p.email === e.target.value);
                setPartner(e.target.value);
                setSelectedPartnerId(selectedPartner ? selectedPartner.id : "");
              }}
              style={{ color: "#6A6A6A" }}
            >
              {partners.map((p) => (
                <MenuItem
                  key={p.id}
                  value={p.email}
                  className="basefont-regular"
                  style={{ color: "#6A6A6A" }}
                >
                  {p.email}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Select for Provider */}
          <FormControl fullWidth style={{ marginTop: "20px" }}>
            <InputLabel id="provider-select-label" className="basefont-regular" style={{ color: "#9B9B9B" }}>
              Provider
            </InputLabel>
            <Select
              labelId="provider-select-label"
              value={provider}
              label="Provider"
              className="basefont-regular"
              onChange={(e) => setProvider(e.target.value)}
              style={{ color: "#6A6A6A" }}
            >
              {providers.map((p) => (
                <MenuItem
                  key={p.id}
                  value={p.id}
                  className="basefont-regular"
                  style={{ color: "#6A6A6A" }}
                >
                  {p.first_name} {p.last_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClinicClose}
            variant="outlined"
            className="active-font"
            style={{
              marginTop: "50px",
              marginBottom: "20px",
              borderColor: "#4BD891",
              color: "black",
              fontSize: "1.0rem",
              minWidth: "140px",
              height: "40px",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleCreateClinic}
            variant="outlined"
            className="active-font"
            style={{
              marginTop: "50px",
              marginBottom: "20px",
              borderColor: "#4BD891",
              color: "black",
              fontSize: "1.0rem",
              minWidth: "140px",
              height: "40px",
            }}
          >
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default AdminHome;
