import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuPane from "./MenuPane";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getFirestore, query, collection, where, getDocs } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { format } from "date-fns";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'; // Chevron icon
import { maxWidth } from "@mui/system";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const PartnerHome = () => {
  const [completedCodes, setCompletedCodes] = useState([]);
  const [patients, setPatients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [clinicName, setClinicName] = useState("");
  const [selectedPatient, setSelectedPatient] = useState(null); // Track selected patient

  useEffect(() => {
    const fetchClinicData = async () => {
      try {
        const userUID = sessionStorage.getItem("userUID");
        if (!userUID) {
          console.error("No userUID found in sessionStorage");
          return;
        }

        const clinicQuery = query(
          collection(db, "clinics"),
          where("partner_id", "==", userUID)
        );
        const clinicSnapshot = await getDocs(clinicQuery);

        if (!clinicSnapshot.empty) {
          const clinicDoc = clinicSnapshot.docs[0];
          const clinicData = clinicDoc.data();
          const { completed_codes = [] } = clinicData;
          const clinicName = clinicData.name || "Unnamed Clinic";
          setClinicName(clinicName);
          setCompletedCodes(completed_codes);

          const patientsCollectionRef = collection(db, `clinics/${clinicDoc.id}/patients`);
          const patientsSnapshot = await getDocs(patientsCollectionRef);

          const fetchedPatients = patientsSnapshot.docs.map((doc) => doc.data());
          setPatients(fetchedPatients);
        } else {
          console.log("No clinic found for the partner");
        }
      } catch (error) {
        console.error("Error fetching clinic data: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchClinicData();
  }, []);

  // Calculate gross total amount for each CPT code
  const calculateGrossAmount = (count, amount) => count * amount;

  if (loading) {
    return (
      <Grid container direction="column" style={{ minHeight: "100vh" }}>
        <Typography variant="h5" align="center">Loading...</Typography>
      </Grid>
    );
  }

  return (
    <Grid container direction="row" style={{ minHeight: "100vh" }}>
      <MenuPane />
      
      <Grid item container xs={12} direction="column" style={{ padding: "50px" }}>
        <Grid container justifyContent="space-between" alignItems="center">
          {/* Clinic Name on the Top Left */}
          <Grid item>
            <Typography variant="h3" style={{marginTop: "50px"}} className="basefont-regular">
              {clinicName}
            </Typography>
            <Typography variant="h6" textAlign="center" className="basefont-regular" style={{marginTop: "55px"}}>
              Completed Codes YTD:
            </Typography>
          </Grid>
        </Grid>

        {/* Horizontal Row of CPT Codes */}
        <Grid container justifyContent="center" spacing={2} mt={2}>
          {completedCodes.length > 0 ? (
            completedCodes.map((code, index) => (
              <Grid item key={index}>
                <Box p={2} textAlign="center">
                  <Typography variant="body1" className="basefont-regular">{code.cpt}</Typography>
                  <Typography variant="body2" className="basefont-regular">{code.description}</Typography>
                  <Typography 
                    variant="body2" 
                    className="basefont-regular"
                    sx={{ 
                      textDecoration: 'underline', 
                      textDecorationColor: '#4BD891', 
                      textDecorationThickness: '3.5px' 
                    }}
                  >
                    Gross Total Amount YTD: ${calculateGrossAmount(code.count, code.amount).toFixed(2)}
                  </Typography>
                </Box>
              </Grid>
            ))
          ) : (
            <Typography>No completed codes found.</Typography>
          )}
        </Grid>
{/* Main Content */}
<Grid container spacing={2} mt={2}>
          {/* Patients Column */}
          <Grid item xs={4} style={{ padding: "20px", borderRight: "1px solid #ccc", maxWidth: "300px" }}>
            <Typography variant="h5" className="basefont-regular">Patients</Typography>
            {patients.length > 0 ? (
              patients.map((patient, index) => (
                <Box
                  key={index}
                  mt={2}
                  p={2}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    cursor: "pointer",
                    borderRadius: "4px",
                    "&:hover": { backgroundColor: "#f0f0f0" },
                  }}
                  onClick={() => setSelectedPatient(patient)} 
                >
                  <Typography variant="body1" className="basefont-regular">
                    {patient.first_name} {patient.last_name}
                  </Typography>
                  <ArrowForwardIosIcon sx={{ fontSize: 16 }} />
                </Box>
              ))
            ) : (
              <Typography>No patients found.</Typography>
            )}
          </Grid>

      {/* Survey Responses Column */}
<Grid item xs={8} style={{ padding: "20px" }}>
  {selectedPatient ? (
    <>
      <Typography variant="h5" className="basefont-regular">
        {selectedPatient.first_name} {selectedPatient.last_name}
      </Typography>
      
      {/* Survey Responses Section */}
      <Box mt={4}>
        <Typography variant="h6" className="basefont-regular">Previous Survey Responses</Typography>
        {selectedPatient.survey_responses && selectedPatient.survey_responses.length > 0 ? (
          selectedPatient.survey_responses.map((response, index) => {
            const completedDate = response.completedDate;
            const formattedDate = completedDate && completedDate.seconds
              ? format(new Date(completedDate.seconds * 1000), 'PPpp')
              : null;

            return (
              <Accordion key={index}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className="basefont-regular">
                    Survey {index + 1} {formattedDate && ` - Completed: ${formattedDate}`}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {Object.entries(response).map(([question, answer], i) => {
                    if (question === 'completedDate') return null;

                    const isTimestamp = answer && answer.seconds && answer.nanoseconds;
                    const formattedAnswer = isTimestamp 
                      ? format(new Date(answer.seconds * 1000), 'PPpp') 
                      : answer;

                    return (
                      <Typography key={i}>
                        <strong>{question}:</strong> {formattedAnswer}
                      </Typography>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            );
          })
        ) : (
          <Typography>No survey responses found.</Typography>
        )}
      </Box>

      {/* Video Calls Section */}
      <Box mt={4}>
        <Typography variant="h6" className="basefont-regular">Completed Video Calls</Typography>
        {selectedPatient.video_calls && selectedPatient.video_calls.length > 0 ? (
          selectedPatient.video_calls.map((call, index) => {
            const completedDate = call.completed_date;
            const formattedDate = completedDate && completedDate.seconds
              ? format(new Date(completedDate.seconds * 1000), 'PPpp')
              : null;

            return (
              <Accordion key={index}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography className="basefont-regular">
                    Video Call {index + 1} {formattedDate && ` - Completed: ${formattedDate}`}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography className="basefont-regular">
                    <strong>Call Length:</strong> {call.call_length} minutes
                  </Typography>
                  <Typography className="basefont-regular">
                    <strong>Notes:</strong> {call.notes}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            );
          })
        ) : (
          <Typography>Video call data not found.</Typography>
        )}
      </Box>
    </>
  ) : (
    <Typography variant="h6">Select a patient to view survey responses and video calls</Typography>
  )}
</Grid>
        </Grid>
      </Grid>
      </Grid>
   

   
  );
};

export default PartnerHome;
