import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './landing-page/HomePage';
import LoginPage from './landing-page/login';
import ForgotPasswordPage from './landing-page/forgotpassword';
import SignupPage from './landing-page/SignupPage';
import DashboardHome from './dashboard/providerhome';
import GetStarted from './landing-page/getstarted';
import PrivacyPolicy from './landing-page/privacypolicy';
import OptIn from './landing-page/opt-in';
import PatientIntake from './dashboard/patientintake';
import AboutUs from './landing-page/about-us';
import Solutions from './landing-page/solutions';
import AdminHome from './dashboard/adminhome';
import Settings from './dashboard/settings';
import ProtectedRoute from './routeprotection';  // import the protected route component
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import PartnerHome from './dashboard/partnerhome';

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };

function App() {
  return (
    <Router>
      <Routes>
        {/* Protected routes */}
        <Route path="/patientintake" element={
          <ProtectedRoute>
            <PatientIntake />
          </ProtectedRoute>
        }/>
         <Route path="/partnerhome" element={
          <ProtectedRoute>
            <PartnerHome />
          </ProtectedRoute>
        }/>
        <Route path="/settings" element={
          <ProtectedRoute>
            <Settings />
          </ProtectedRoute>
        }/>
        <Route path="/providerhome" element={
          <ProtectedRoute>
            <DashboardHome />
          </ProtectedRoute>
        }/>
        <Route path="/adminhome" element={
          <ProtectedRoute>
            <AdminHome />
          </ProtectedRoute>
        }/>
        
        {/* Public routes */}
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/getstarted" element={<GetStarted />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/opt-in" element={<OptIn />} />
        <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
      </Routes>
    </Router>
  );
}

export default App;
