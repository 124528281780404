import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, Button, IconButton, Drawer, List, ListItem, ListItemText } from "@mui/material";
import { Link } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { doc, getDoc, getFirestore, collection, getDocs, query, where } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { getAuth, signOut } from "firebase/auth"; 
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const MenuPane = ({ exitSession }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [homeRoute, setHomeRoute] = useState("/"); // Default route
  const [showError, setShowError] = useState(""); // Holds detailed error messages
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleLogout = async () => {
    const auth = getAuth(); // Get the Firebase auth instance
    try {
      exitSession()
      await signOut(auth); // Sign out from Firebase
    } catch (error) {
      setShowError(`Error logging out: ${error.message}`); // Handle any errors
    }
  };


  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const uid = sessionStorage.getItem('userUID');
        console.log("Fetched User UID:", uid);
        
        if (!uid) {
          setShowError("User UID not found in session storage.");
          return;
        }
        
        const userDocRef = doc(db, 'users', uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (userDocSnapshot.exists()) {
          const userData = userDocSnapshot.data();
          const userRole = userData.role;

          console.log("User Data:", userData);
          console.log("User Role:", userRole);

          // Route assignment based on role
          if (userRole === 'provider') {
            setHomeRoute("/providerhome");
          } else if (userRole === 'admin') {
            setHomeRoute("/adminhome");
          } else if (userRole === 'partner') {
            setHomeRoute("/partnerhome");
          } else {
            setShowError(`Unknown role: ${userRole}`);
          }
        } else {
          setShowError("User document not found.");
        }
      } catch (error) {
        setShowError(`Error fetching user role: ${error.message}`);
      }
    };

    fetchUserRole();
  }, [db]);

  const drawer = (
    <div onClick={handleDrawerToggle}>
      <List>
        <ListItem button component={Link} to={homeRoute} className="active-font">
          <ListItemText primary="Home" classes={{ primary: 'active-font' }} />
        </ListItem>
        <ListItem button component={Link} to="/settings" onClick={exitSession} className="active-font">
          <ListItemText primary="Settings" classes={{ primary: 'active-font' }} />
        </ListItem>
        <ListItem button component={Link} onClick={handleLogout} to="/login" className="active-font">
          <ListItemText primary="Logout" classes={{ primary: 'active-font' }} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <AppBar position="fixed" style={{ backgroundColor: "white" }}>
      <Toolbar style={{ justifyContent: isMobile ? "space-between" : "center" }}>
        {isMobile ? (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
              style={{ color: "black" }}
            >
              <MenuIcon />
            </IconButton>
            <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
              {drawer}
            </Drawer>
          </>
        ) : (
          <>
            <Button
              component={Link}
              to={homeRoute}
              color="inherit"
              className="active-font"
              style={{ color: "black" }}
            >
              Home
            </Button>
            <Button
              component={Link}
              onClick={exitSession}
              to="/settings"
              color="inherit"
              className="active-font"
              style={{ color: "black" }}
            >
              Settings
            </Button>
            <Button
              component={Link}
              onClick={handleLogout}
              to="/login"
              color="inherit"
              className="active-font"
              style={{ color: "black" }}
            >
              Logout
            </Button>
          </>
        )}
      </Toolbar>
      {showError && <p style={{ color: 'red' }}>{showError}</p>}
    </AppBar>
  );
};

export default MenuPane;
