import React, { useState } from "react";
import {
  Grid,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from "@mui/material";
import VitalQueTextField from "../landing-page/VitalQueTextField";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
  arrayUnion,
  arrayRemove,
  Timestamp
} from "firebase/firestore";

const VideoCallSubmission = ({ selectedClinicID, patientID, db }) => {
  const [videoCallDate, setVideoCallDate] = useState(null);
  const [callDurationHours, setCallDurationHours] = useState("0");
  const [callDurationMinutes, setCallDurationMinutes] = useState("0");
  const [callDurationSeconds, setCallDurationSeconds] = useState("0");
  const [patientNotes, setPatientNotes] = useState("");


  const handleSignup = async () => {
    if (!videoCallDate || !patientNotes) {
      alert("All fields are required.");
      return;
    }

    // Calculate call duration in total minutes
    const totalMinutes = 
      parseInt(callDurationHours) * 60 +
      parseInt(callDurationMinutes) +
      parseInt(callDurationSeconds) / 60;

    // Create the new video call entry
    const newVideoCall = {
      call_length: totalMinutes,
      completed_date: Timestamp.fromDate(videoCallDate),
      notes: patientNotes,
    };

    try {
      // Reference to the patient's document
      const patientDocRef = doc(db, "clinics", selectedClinicID, "patients", patientID);

      // Update the patient's video_calls array
      await updateDoc(patientDocRef, {
        video_calls: arrayUnion(newVideoCall),
      });

      alert("Video call submission successful!");
    } catch (error) {
      console.error("Error submitting video call:", error);
      alert("Failed to submit video call.");
    }
  };

  
  const hoursOptions = Array.from({ length: 24 }, (_, hour) => hour.toString());
  const minutesSecondsOptions = Array.from({ length: 60 }, (_, unit) => unit.toString());

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} >
      <Grid
        container
        spacing={0}
        justifyContent="center"
        alignItems="center"
        style={{ padding: "20px" }}
      >
        <Grid item xs={12} container direction="column" alignItems="center">
          <Typography
            variant="h3"
            align="center"
            className="active-font"
            style={{ color: "black", marginTop: "30px", marginBottom: "50px" }}
          >
            Post Video Call Submission
          </Typography>
          {/* Date Picker for Date of Video Call */}
          <DatePicker
            label="Date of Video Call"
            value={videoCallDate}
            onChange={(date) => setVideoCallDate(date)}
            renderInput={(params) => (
              <VitalQueTextField {...params} required style={{ marginTop: "20px" }} />
            )}
          />

          {/* Call Duration */}
          <Typography variant="h6" className="basefont-regular" style={{ marginTop: "20px", color: "black" }}>
            Call Duration:
          </Typography>
          <Grid container spacing={1} justifyContent="center" style={{ marginTop: "10px" }}>
            <Grid item>
            <InputLabel className="basefont-regular">Hrs</InputLabel>
              <FormControl>
                <Select
                  value={callDurationHours}
                  onChange={(e) => setCallDurationHours(e.target.value)}
                  required
                >
                  {hoursOptions.map((hour) => (
                    <MenuItem className="basefont-regular" key={hour} value={hour}>
                      {hour}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
            <InputLabel className="basefont-regular">Mins</InputLabel>
              <FormControl>
                <Select
                  value={callDurationMinutes}
                  onChange={(e) => setCallDurationMinutes(e.target.value)}
                  required
                >
                  {minutesSecondsOptions.map((minute) => (
                    <MenuItem className="basefont-regular" key={minute} value={minute}>
                      {minute}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
            <InputLabel className="basefont-regular">Secs</InputLabel>
              <FormControl>
                <Select
                  value={callDurationSeconds}
                  onChange={(e) => setCallDurationSeconds(e.target.value)}
                  required
                >
                  {minutesSecondsOptions.map((second) => (
                    <MenuItem className="basefont-regular" key={second} value={second}>
                      {second}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {/* Patient Update Notes */}
          <Typography variant="h6" className="basefont-regular" style={{ marginTop: "20px", color: "black" }}>
            Patient Update Notes
          </Typography>
            <TextField
            value={patientNotes}
            onChange={(e) => setPatientNotes(e.target.value)}
            required
            multiline
            rows={10}
            variant="outlined"
            className="basefont-regular"
            style={{
              marginTop: "20px",
              width: "100%",
              minHeight: "150px",
              padding: "10px",
            }}
            InputProps={{
              style: {
                fontFamily: "Montserrat, sans-serif",  // Change to your preferred font family
                fontSize: "16px",                 // Set font size
                color: "black",                   // Text color
              },
            }}
          />
          <Button
            onClick={handleSignup}
            variant="outlined"
            className="active-font"
            style={{
              marginTop: "30px",
              borderColor: "#4BD891",
              color: "black",
              fontSize: "1.0rem",
              minWidth: "140px",
              height: "40px",
            }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
      </LocalizationProvider >
  );
};

export default VideoCallSubmission;
